import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import Form from '../forms/Form'
import {virszemesUdensObjektsKimActions, virszemesUoKimYearFetch} from '../../actions/virszemesUdensObjektiActions'
import FormCard from '../forms/FormCard'
import {
  Row,
} from 'reactstrap'
import {getBooleanFormatter, getNumberFormatter} from "../grid/ColumnHelpers";
import {getTableRenderer, mdToGridColumns} from "./common";
import {getKlasifikatorsFieldCfg, getKodifRenderer} from "../forms/FormHelpers";
import NextPrevBox from "../forms/NextPrevBox";

const getFormFieldWrapperCfgs = () => {
  return [
    {
      id: 'kvalitate_wrap',
      comp: FormCard,
      compProps: {
        title: "",
        pinnable: false,
        size: 12
      },
    },
    {
      id: 'udens_matrica_wrap',
      comp: FormCard,
      compProps: {
        title: 'Ūdens matrica',
        pinnable: true,
        size: 12
      },
    },
    {
      id: 'zivju_matrica_wrap',
      comp: FormCard,
      compProps: {
        title: 'Zivju matrica',
        pinnable: true,
        size: 12
      },
    },
    {
      id: 'gliemju_matrica_wrap',
      comp: FormCard,
      compProps: {
        title: 'Gliemju matrica',
        pinnable: true,
        size: 12
      },
    },
    {
      id: 'bistamas_wrap',
      comp: FormCard,
      compProps: {
        title: 'Bīstamās vielas',
        pinnable: true,
        size: 12
      },
    },
    {
      id: 'visi_merijumi_wrap',
      comp: FormCard,
      compProps: {
        title: 'Visi mērījumi',
        pinnable: false,
        collapse: true,
        size: 12
      },
    },
  ]
}

const getFormFieldCfgs = ({ kodif, metadata }) => () => {

  const numberFmt = getNumberFormatter()

  const avgFormatter = (cellContent, row) => {
    if (row.ir_summarais) {
      return numberFmt(cellContent)
    } else {
      if (row.avg_vertiba <= row.ql_avg) {
        return `< ${numberFmt(row.ql_max)}`
      } else {
        return numberFmt(cellContent)
      }
    }
  }

  const maxFormatter = (cellContent, row) => {
    console.log(cellContent, row)
    if (row.ir_summarais) {
      return numberFmt(cellContent)
    } else {
      if (row.max_vertiba <= row.ql_max) {
        return `< ${numberFmt(row.ql_max)}`
      } else {
        return numberFmt(cellContent)
      }
    }
  }

  const commonProps = {
    label: undefined,
    sizeLabel: 0,
    sizeForm: 12
  }

  const udensCols = [
    {
      name: 'nr_mk',
      type: 'number',
      width: 30
    },
    {
      name: 'nosaukums_mk',
    },
    {
      name: 'nosaukums',
      classes: 'display-linebreak'
    },
    {
      name: 'grupa',
    },
    {
      name: 'cas_nr',
    },
    {
      name: 'gvk',
      type: 'number',
      formatter: getNumberFormatter(),
      width: 60,
      text: 'GVK'
    },
    {
      name: 'mpk',
      type: 'number',
      formatter: getNumberFormatter(),
      width: 60,
      text: 'MPK'
    },
    {
      name: 'avg_vertiba',
      type: 'number',
      formatter: avgFormatter,
      classes: (cell, row) => {
        if (row.kvalitate_avg) {
          return `kvalitate-${row.kvalitate_avg}`
        }
        return ''
      },
      width: 60
    },
    {
      name: 'max_vertiba',
      type: 'number',
      formatter: maxFormatter,
      classes: (cell, row) => {
        if (row.kvalitate_max) {
          return `kvalitate-${row.kvalitate_max}`
        }
        return ''
      },
      width: 60
    },
    {
      name: 'mervieniba',
    },
  ]

  const otherCols = [
    {
      name: 'nr_mk',
      type: 'number'
    },
    {
      name: 'nosaukums_mk',
    },
    {
      name: 'nosaukums',
      classes: 'display-linebreak'
    },
    {
      name: 'grupa',
    },
    {
      name: 'cas_nr',
    },
    {
      name: 'mpk',
      type: 'number',
      text: 'MPK',
      formatter: getNumberFormatter(),
      width: 60
    },
    {
      name: 'max_vertiba',
      type: 'number',
      formatter: maxFormatter,
      classes: (cell, row) => {
        if (row.kvalitate) {
          return `kvalitate-${row.kvalitate}`
        }
        return ''
      },
      width: 60
    },
    {
      name: 'mervieniba',
    },
  ]

  const bistamasCols = [
    {
      name: 'nosaukums_mk',
    },
    {
      name: 'nosaukums',
      classes: 'display-linebreak'
    },
    {
      name: 'gvk',
      type: 'number',
      text: 'GVK',
      formatter: getNumberFormatter(),
      width: 60
    },
    {
      name: 'avg_vertiba',
      type: 'number',
      formatter: avgFormatter,
      classes: (cell, row) => {
        if (row.kvalitate) {
          return `kvalitate-${row.kvalitate}`
        }
        return ''
      },
      width: 60
    },
    {
      name: 'mervieniba',
    },
  ]

  const getGrid = (view, cols) => {
    return getTableRenderer(
      mdToGridColumns(view, cols, metadata)
    )
  }

  return [
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.kimiska',
      label: 'Kopējā ķīmiskā kvalitāte',
      fieldWrapper: 'kvalitate_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.kimiska_bez_pbt',
      label: 'Kopējā ķīmiskā kvalitāte bez noturīgajām, bioakumulatīvajām un toksiskajām (PBT) vielām',
      fieldWrapper: 'kvalitate_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.kimiska_apr',
      editable: false,
      label: 'Kopējā ķīmiskā kvalitāte (aprēķinātā)',
      fieldWrapper: 'kvalitate_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      ...commonProps,
      name: 'udens_matrica',
      editable: false,
      renderer: getGrid('virszemes_udens_objekts_kvalitate_kimiska_udens_matrica', udensCols),
      fieldWrapper: 'udens_matrica_wrap',
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.kimiska_udens',
      label: 'Kvalitāte ūdens matricā',
      fieldWrapper: 'udens_matrica_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.kimiska_udens_apr',
      editable: false,
      label: 'Kvalitāte ūdens matricā (aprēķinātā)',
      fieldWrapper: 'udens_matrica_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      ...commonProps,
      name: 'zivju_matrica',
      editable: false,
      renderer: getGrid('virszemes_udens_objekts_kvalitate_kimiska_zivju_matrica', otherCols),
      fieldWrapper: 'zivju_matrica_wrap',
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.kimiska_zivju',
      label: 'Kvalitāte zivju matricā',
      fieldWrapper: 'zivju_matrica_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.kimiska_zivju_apr',
      editable: false,
      label: 'Kvalitāte zivju matricā (aprēķinātā)',
      fieldWrapper: 'zivju_matrica_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      ...commonProps,
      name: 'gliemju_matrica',
      editable: true,
      renderer: getGrid('virszemes_udens_objekts_kvalitate_kimiska_zivju_matrica', otherCols),
      fieldWrapper: 'gliemju_matrica_wrap',
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.kimiska_gliemju',
      label: 'Kvalitāte gliemju matricā',
      fieldWrapper: 'gliemju_matrica_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.kimiska_gliemju_apr',
      editable: false,
      label: 'Kvalitāte gliemju matricā (aprēķinātā)',
      fieldWrapper: 'gliemju_matrica_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      ...commonProps,
      name: 'bistamas',
      editable: false,
      renderer: getGrid('virszemes_udens_objekts_kvalitate_kimiska_bistamas', bistamasCols),
      fieldWrapper: 'bistamas_wrap',
    },
    {
      // ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.kimiska_bistamas_apr',
      editable: false,
      label: 'Ir vides kvalitātes normatīva pārsniegums',
      fieldWrapper: 'bistamas_wrap',
      renderer: (v) => {
        if (v !== undefined && v !== null) {
          return getBooleanFormatter()(v)
        } else {
          return "Nav datu"
        }
      }
    },
    {
      ...commonProps,
      name: 'visi_merijumi',
      editable: true,
      fieldWrapper: 'visi_merijumi_wrap',
      multiFieldColsToGroup: 4,
      multiFieldCfgs: [{
        name: 'matrica',
        renderer: getKodifRenderer(kodif.kimiskaMatrica)
      }, {
        name: 'nr_mk',
      }, {
        name: 'nosaukums_mk',
      }, {
        name: 'merijuma_datums',
      }, {
        name: 'parauga_numurs',
      }, {
        name: 'vide',
      }, {
        name: 'metodika',
      }, {
        name: 'nosaukums',
      }, {
        name: 'merijuma_vertiba',
        renderer: getNumberFormatter()
      }, {
        name: 'aprekinata_vertiba',
        renderer: getNumberFormatter()
      }, {
        name: 'vertiba_avg',
        renderer: getNumberFormatter()
      }, {
        name: 'vertiba_max',
        renderer: getNumberFormatter()
      }, {
        name: 'koef',
        renderer: getNumberFormatter()
      }, {
        name: 'ir_ql',
      }, {
        name: 'ql_vertiba_def',
        renderer: getNumberFormatter()
      }, {
        name: 'ql_vertiba_izmantota',
        renderer: getNumberFormatter()
      }]
    },
  ]
}

class KimiskaComp extends Component {


  render() {

    const {virszemesUdensObjektsKim, user, getFormFieldCfgsFunc, kodif,
      getFormFieldWrapperCfgsFunc, metadata} = this.props

    const {selectedItem, editable, submiting} = virszemesUdensObjektsKim

    // loading while we have no virszemesUdensObjekts
    if (!selectedItem) {
      return (<Loading/>)
    }

    const onNextPrevYear = (gads) => {
      this.props.yearFetch({ kods: selectedItem.kods, gads })
    }

    // we cache form field cfgs
    // reload form fields if virszemesUdensObjekts changes
    if(!this.formFieldCfgs || virszemesUdensObjektsKim.id !== this.prevUoId){
      const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs({ kodif, metadata })
      this.formFieldCfgs = f({
        kodif,
        user,
        metadata
      })
      this.prevUoId = selectedItem.id
    }

    if(!this.formFieldWrapperCfgs){
      const f = getFormFieldWrapperCfgsFunc ? getFormFieldWrapperCfgsFunc : getFormFieldWrapperCfgs
      this.formFieldWrapperCfgs = f()
    }

    return <div className="form-container">
      <NextPrevBox currentValue={selectedItem.gads} onChange={onNextPrevYear}></NextPrevBox>
      <Form
        sizeLabel={2}
        editable={editable}
        submiting={submiting}
        dataObj={selectedItem}
        formFieldCfgs={this.formFieldCfgs}
        formFieldWrapperCfgs={this.formFieldWrapperCfgs}
        onSubmit={this.props.saveAct}
        onReset={this.props.stopEditAct}
        onStartEdit={this.props.startEditAct}
        formWrapperComp={Row}
        metadataFormCfg="virszemes_udens_objekts_kvalitate_kimiska"
        tiesibasLabot="BAIS_VU_KVAL_LABOT"
      />
    </div>
  }
}

const mapStateToProps = ({virszemesUdensObjektsKim, kodif, user, metadata}) => ({virszemesUdensObjektsKim, kodif, user, metadata})
const VirszemesKimiska =  connect(mapStateToProps, { yearFetch: virszemesUoKimYearFetch, ...virszemesUdensObjektsKimActions })(KimiskaComp)
export default VirszemesKimiska


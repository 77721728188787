import React, { PureComponent } from 'react'

import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { connect } from "react-redux";
import { exportOds, hostize } from "../../api";
import { routesMap } from "../../routes";

class ExportButton extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      loadingPopup: false
    }
  }

  toggleModal = (visible) => {
    this.setState({
      loadingPopup: visible
    })
  }

  exportOds = () => {
    const route = routesMap[this.props.type]
    if (!routesMap) {
      return
    }
    this.toggleModal(true)
    const mapSize = this.props.query && this.props.query.mapId ? this.props.gui[`${this.props.query.mapId}_karte_size`] : null
    exportOds({
      mapSize,
      viewName: this.props.viewname || route.viewname,
      param: this.props.query
    }).then((hash) => {
      this.toggleModal(false)
      window.open(hostize(`/deferred/${hash}/result`))
    }).catch((err) => {
      this.toggleModal(false)
    })
  }

  render() {
    return <>
      <Button color="secondary" onClick={this.exportOds}><i className="fa fa-file-excel-o"></i>&nbsp;&nbsp;Eksportēt</Button>
      <Modal
        isOpen={this.state.loadingPopup}
        className="modal-sm">
        <ModalHeader>Notiek datu sagatavošana</ModalHeader>
        <ModalBody className="text-center">
          <Spinner size="lg" color="primary" />{' '}
        </ModalBody>
      </Modal>
    </>
  }
}

const mapStateToProps = ({ location, gui }) => {
  return {
    gui,
    query: location.query,
    type: location.type
  }
}

export default connect(mapStateToProps)(ExportButton)